@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400;500;700&display=swap');

.sample-selector .swiper {
  width: 100%;
  height: 100%;
}

.sample-selector .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: transparent;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.sample-selector .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.sample-selector .swiper-horizontal > .swiper-pagination-bullets,
.sample-selector .swiper-pagination-bullets.swiper-pagination-horizontal,
.sample-selector .swiper-pagination-custom,
.sample-selector .swiper-pagination-fraction {
  bottom: 0px;
  left: 0;
  width: 100%;
}

.gallery-thumbs .swiper-slide {
  width: 100%;
  text-align: center;
  box-sizing: border-box;
  overflow: hidden;
}
.gallery-thumbs .swiper-slide-thumb-active .thumb-box {
  border: 2px solid #d96c06;
}
.gallery-thumbs .swiper-slide-thumb-active img {
  opacity: 1;
}
.gallery-thumbs .swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.8;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.mySwiper2 {
  border: 1px solid #b8b8b8;
  padding: 10px;
}
.gallery-slider .swiper {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.mySwiper2 .swiper-slide {
  background-size: contain;
  background-position: center;
  padding-top: 100%;
  position: relative;
}
.mySwiper2 .swiper-wrapper {
  padding: 10px;
}

.mySwiper3 .swiper-slide {
  padding: 5px;
  border: 1px solid #b8b8b8;
}

.gallery-slider .mySwiper3 {
  padding: 10px;
}

.gallery-slider .mySwiper {
  box-sizing: border-box;
  padding: 10px 0;
}

.gallery-slider .mySwiper .swiper-slide {
  width: 20%;
  height: 100%;
  opacity: 0.4;
  border: 1px solid #ddd;
  box-sizing: border-box;
}

.gallery-slider .mySwiper .swiper-slide-thumb-active {
  opacity: 1;
  border: 2px solid #d96c06;
}

.gallery-slider .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.table {
  background-color: white;
  color: #363636;
  width: 100%;
}

.table td,
.table th {
  border: 1px solid #dbdbdb;
  border-width: 0 0 1px;
  padding: 0.5em 0.75em;
  vertical-align: top;
}

.table td.is-white,
.table th.is-white {
  background-color: white;
  border-color: white;
  color: #0a0a0a;
}

.table td.is-black,
.table th.is-black {
  background-color: #0a0a0a;
  border-color: #0a0a0a;
  color: white;
}

.table td.is-light,
.table th.is-light {
  background-color: whitesmoke;
  border-color: whitesmoke;
  color: rgba(0, 0, 0, 0.7);
}

.table td.is-dark,
.table th.is-dark {
  background-color: #363636;
  border-color: #363636;
  color: #fff;
}

.table td.is-primary,
.table th.is-primary {
  background-color: #00d1b2;
  border-color: #00d1b2;
  color: #fff;
}

.table td.is-link,
.table th.is-link {
  background-color: #485fc7;
  border-color: #485fc7;
  color: #fff;
}

.table td.is-info,
.table th.is-info {
  background-color: #3e8ed0;
  border-color: #3e8ed0;
  color: #fff;
}

.table td.is-success,
.table th.is-success {
  background-color: #48c78e;
  border-color: #48c78e;
  color: #fff;
}

.table td.is-warning,
.table th.is-warning {
  background-color: #ffe08a;
  border-color: #ffe08a;
  color: rgba(0, 0, 0, 0.7);
}

.table td.is-danger,
.table th.is-danger {
  background-color: #f14668;
  border-color: #f14668;
  color: #fff;
}

.table td.is-narrow,
.table th.is-narrow {
  white-space: nowrap;
  width: 1%;
}

.table td.is-selected,
.table th.is-selected {
  background-color: #00d1b2;
  color: #fff;
}

.table td.is-selected a,
.table td.is-selected strong,
.table th.is-selected a,
.table th.is-selected strong {
  color: currentColor;
}

.table td.is-vcentered,
.table th.is-vcentered {
  vertical-align: middle;
}

.table th {
  color: #363636;
}

.table th:not([align]) {
  text-align: left;
}

.table tr.is-selected {
  background-color: #00d1b2;
  color: #fff;
}

.table tr.is-selected a,
.table tr.is-selected strong {
  color: currentColor;
}

.table tr.is-selected td,
.table tr.is-selected th {
  border-color: #fff;
  color: currentColor;
}

.table thead {
  background-color: transparent;
}

.table thead td,
.table thead th {
  border-width: 0 0 2px;
  color: #363636;
}

.table tfoot {
  background-color: transparent;
}

.table tfoot td,
.table tfoot th {
  border-width: 2px 0 0;
  color: #363636;
}

.table tbody {
  background-color: transparent;
}

.table tbody tr:last-child td,
.table tbody tr:last-child th {
  border-bottom-width: 0;
}

.table.is-bordered td,
.table.is-bordered th {
  border-width: 1px;
}

.table.is-bordered tr:last-child td,
.table.is-bordered tr:last-child th {
  border-bottom-width: 1px;
}

.table.is-fullwidth {
  width: 100%;
}

.table.is-hoverable tbody tr:not(.is-selected):hover {
  background-color: #fafafa;
}

.table.is-hoverable.is-striped tbody tr:not(.is-selected):hover {
  background-color: #fafafa;
}

.table.is-hoverable.is-striped
  tbody
  tr:not(.is-selected):hover:nth-child(even) {
  background-color: whitesmoke;
}

.table.is-narrow td,
.table.is-narrow th {
  padding: 0.25em 0.5em;
}

.table.is-striped tbody tr:not(.is-selected):nth-child(even) {
  background-color: #fafafa;
}

.table-container {
  -webkit-overflow-scrolling: touch;
  overflow: auto;
  overflow-y: hidden;
  max-width: 100%;
}

.navSwiper .swiper-button-next,
.navSwiper,
.swiper-button-prev {
  color: #d96c06;
}
